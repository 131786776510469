import * as Sentry from '@sentry/vue'
import { EventSourcePolyfill } from 'event-source-polyfill'

import { getHeaders } from '~/lib/api/apiHelpers'
import { Deal } from '~/types/api'

export interface GetTripDealsResponse {
    deals?: Deal[]
    status: GetTripDealsFetchStatus
}

export enum GetTripDealsFetchStatus {
    WAITING = 'WAITING',
    PENDING = 'PENDING',
    END = 'END',
    ERROR = 'ERROR',
}

export interface TripDealsState {
    status: GetTripDealsFetchStatus
    deals: Deal[]
}

export const tripDealsInitialData: TripDealsState = {
    status: GetTripDealsFetchStatus.WAITING,
    deals: [],
}

export const MAX_DEALS_TO_SHOW = 50

export const useTripDealsStore = defineStore(`tripDeals`, () => {
    const deals = ref([] as Deal[])
    const status = ref(tripDealsInitialData.status)
    const config = useRuntimeConfig()
    let tripId: string
    let eventSource: EventSourcePolyfill

    function replaceDeals(replaceDeals: Deal[]) {
        deals.value = replaceDeals.slice(0, MAX_DEALS_TO_SHOW)
    }

    function reset() {
        eventSource?.close()
        deals.value = []
        status.value = GetTripDealsFetchStatus.WAITING
    }

    async function setTripId(newTripId: string) {
        if (!import.meta.client) return

        if (newTripId !== tripId) {
            reset()
            tripId = newTripId
            eventSource = new EventSourcePolyfill(
                `${config.public.apiHost}/trips/${tripId}/deals`,
                {
                    withCredentials: true,
                    headers: await getHeaders({
                        contentType: 'text/event-stream',
                    }),
                    heartbeatTimeout: 1000 * 120, // 2 minutes
                }
            )
            eventSource.onmessage = (ev) => {
                const data = JSON.parse(ev.data) as GetTripDealsResponse

                console.log(`[tripId: ${tripId}] event source data:`, data)

                if (data.deals) {
                    replaceDeals(data.deals)
                }

                status.value = data.status

                if (data.status === GetTripDealsFetchStatus.END) {
                    eventSource!.close()
                }
            }
            eventSource.onerror = (ev: any) => {
                eventSource!.close()

                // check if timeout
                if (ev.error?.message?.includes('No activity within')) {
                    status.value = GetTripDealsFetchStatus.END
                } else {
                    status.value = GetTripDealsFetchStatus.ERROR

                    try {
                        const error = new Error(ev.error?.message)
                        Sentry.captureException(error)
                    } catch (errorCaught) {}
                }
            }
        }
    }

    return {
        deals,
        status,
        setTripId,
    }
})
